import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "./settings.css";

var tempArray = [];
var tempArrayTheme = [];

function Settings() {
  const [similarity, setSimilarity] = useState(["Subject"]);
  const [theme, setTheme] = useState(["State Board"]);
  const [similarityText, setSimilarityText] = useState("");
  const [themeText, setThemeText] = useState("");

  console.log(similarity);

  function handleSimilarityInput(e) {
    var siText = e.target.value;
    setSimilarityText(siText);
  }

  function handleSimilarityOk() {
    if(similarityText === ""){
      toast.error("Please enter the text")
    }else{
    setSimilarity([...similarity, similarityText]);
    setSimilarityText("");
    }
  }

  function handleDeleteSimilarity(text) {
    tempArray = similarity;
    var index = tempArray.indexOf(text);
    tempArray.splice(index, 1);
    setSimilarity([...tempArray]);
  }

  function handleThemeInput(e) {
    var thText = e.target.value;
    setThemeText(thText);
  }

  function handleThemeOk() {
    if(themeText === ""){
      toast.error("Please enter the text")
    }else{
      setTheme([...theme, themeText]);
    setThemeText("");
    }
  }

  function handleDeleteTheme(text) {
    tempArrayTheme = theme;
    var indexTheme = tempArrayTheme.indexOf(text);
    tempArrayTheme.splice(indexTheme, 1);
    setTheme([...tempArrayTheme]);
  }

  return (
    <div className="mainDiv">
      <h1 className="h1Settings">Settings</h1>
      <div className="containerDiv">
        <div className="subContainerDiv">
          <h2 className="h2Settings">Similarity</h2>
          <input
            type="text"
            onChange={(e) => handleSimilarityInput(e)}
            placeholder="Enter Here"
            value={similarityText}
            className="settingsInput"
          ></input>
          <button className="settingsButton" onClick={handleSimilarityOk}>
            Ok
          </button>
          <div className="boxContainer">
            <div className="boxDiv">
              {similarity.map((text) => {
                return (
                  <div className="tagDiv">
                    <p className="tagP">{text}</p>
                    <button
                      className="tagButton"
                      onClick={(e) => handleDeleteSimilarity(text)}
                    >
                      X
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="subContainerDiv">
          {/* <div className="subContainerDiv"> */}
            <h2 className="h2Settings">Theme</h2>
            <input
              type="text"
              onChange={(e) => handleThemeInput(e)}
              placeholder="Enter Here"
              value={themeText}
              className="settingsInput"
            ></input>
            <button className="settingsButton" onClick={handleThemeOk}>Ok</button>
          {/* </div> */}
          <div className="boxContainer">
            <div className="boxDiv">
              {theme.map((text) => {
                return (
                  <div className="tagDiv">
                    <p className="tagP">{text}</p>
                    <button
                      className="tagButton"
                      onClick={(e) => handleDeleteTheme(text)}
                    >
                      X
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default Settings;
