import React from "react";
import rows from "../../../reportsData";
import "./reports.css";

function Reports() {
  const thead_column = [
    "Study Circle",
    "Subject",
    "Topic",
    "Day",
    "Student",
    "Score",
    "Attendance",
  ];

  const search = [
    "#548745 - Class 9th English Student list",
    "#548799 - Discovery could help scientists Class 9 consciousness",
    "#548748 - Class 9 understand consciousness, Writing Skills",
    "#548748 - Class 9 understand consciousness, Writing Skills",
    "#5421478- This Instagram account documents Listening skills",
    "#548799 - Brain cell discovery could help scientists Class 9",
    "#548799 - Discovery could help scientists Class 9 consciousness",
  ];

  return (
    <div className="reportsContainer">
      <div className="subContainer">
        <div>
          <button className="all">All</button>
          <button className="bookmarks">Bookmarks</button>
          <button className="recent">Recent</button>
        </div>
        <div>
          <input className="reportSerach" type="search" />
        </div>
        <hr />
        <div>
          {search.map((e, key) => {
            return (
              <div>
                <p key={key} className="searchItem">
                  {e}
                </p>
                <hr />
              </div>
            );
          })}
        </div>
      </div>
      <div className="subContainer2">
        <div className="exSubContainer2">
          <div>
            <h1 className="heading"># 149223-All Student Score Above 4.5</h1>
          </div>
          <div>
            <button className="save"></button>
            <button className="xls"></button>
            <button className="pdf"></button>
            <button className="zoom"></button>
          </div>
        </div>
        <div>
          <table className="tableContainerReports">
            <thead>
              <tr>
                {thead_column.map((e1, key) => {
                  return <th key={key}>{e1}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {rows.map((rows, key) => {
                return (
                  <tr key={key}>
                    <td>{rows.StudyCircle}</td>
                    <td>{rows.Subject}</td>
                    <td>{rows.Topic}</td>
                    <td>{rows.Day}</td>
                    <td>
                      {<img src={rows.Image} className="imgStudent" alt="" />}
                      {rows.Student}
                    </td>
                    <td>{rows.Score}</td>
                    <td>{rows.Attendance}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Reports;
