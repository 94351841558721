import React from "react";
import { Modal } from "react-bootstrap";
import  "./modal.css";

function ModalComponent(props) {
  const { children, show, onClick, title, className, onChange} = props;
  return (
    <Modal show={show} onHide={onClick} className={className} onChange={onChange} backdrop="static">
      <Modal.Header closeButton className="title">
        {title}
      </Modal.Header>

      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
}

export default ModalComponent;
